body {
  margin: 0 !important;
  font-family: 'Nunito Sans', sans-serif;

}


code {
  font-family: 'Nunito Sans', sans-serif !important
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

::-webkit-scrollbar{
  width: 0.4em;
}
::-webkit-scrollbar-track{
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  -webkit-box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
}
::-webkit-scrollbar-thumb{
  background-color: rgba(97, 97, 97, 0.61);
  border: 1px solid #70809000;
  border-radius: 10px !important;
}


/* .storemapper-iw-container, .gm-style .gm-style-iw-c, .gm-style .gm-style-iw-d, .gm-style .gm-style-iw-tc::after, .gm-style .gm-style-iw-tc::before, .gm-style-iw  { 
  background: "#F6F4FC !important";
  border-radius: 8px;
box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
font-size: 13px;
font-weight: 300;
padding: 0px !important;
} */

.gm-style-iw-d {
  overflow: auto !important;
}

.gm-style .gm-style-iw-tc::after, .gm-style .gm-style-iw-tc::before {
  background: #F6F4FC !important;
}

.gm-style-iw-t > div > button {
  display: none !important;
}

.gm-style-iw {
  background: "#F6F4FC !important";
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
  font-size: 13px;
  font-weight: 300;
  padding: 0px !important;
}

.gm-style .gm-style-iw-c {
  left : 64px !important;
}

.gm-fullscreen-control {
  top : 50px !important
}

